import { Box } from '@mui/material'
import SAMAdvancedRealTimeChart from '~/components/trading-view/advanced-realtime-chart'
import NewsFeed from '~/components/trading-view/news-feed'
import { SAMTickerTape } from '~/components/trading-view/ticker-tape'

const IndexPage = () => {
  return (
    <>
      <Box
        sx={{
          marginBottom: '1rem',
          width: 'calc(100% + 10rem)',
          marginLeft: '-5rem',
          marinRight: '-5rem',
        }}
      >
        <SAMTickerTape />
      </Box>
      <SAMAdvancedRealTimeChart />
      <NewsFeed />
    </>
  )
}

export default IndexPage
