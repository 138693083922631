import React from 'react'
import Image from 'next/image'
import { Modal, Box, Typography, IconButton, Container } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Post } from '~/lib/sanity.queries'
import { urlForImage } from '~/lib/sanity.image'
import { AugmentedPortableText } from '~/pages/insights/post/components/augmented-portable-text'
import { formatDate } from '~/utils'

interface NewsModalProps {
  open: boolean
  onClose: () => void
  post?: Post
}

const NewsModal = ({ open, onClose, post }: NewsModalProps) => {
  return !post ? (
    <></>
  ) : (
    <Modal open={open} onClose={onClose}>
      <>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '80%', md: '60%' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '90vh',
            overflow: 'auto',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'sticky',
              right: 8,
              top: 8,
              float: 'right',
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Container>
            <section className="post">
              {post.mainImage ? (
                <Image
                  className="post__cover"
                  src={urlForImage(post.mainImage).url()}
                  height={231}
                  width={367}
                  alt=""
                  style={{
                    display: 'block',
                    margin: '0 auto',
                  }}
                />
              ) : (
                <div className="post__cover--none" />
              )}
              <div className="post__container">
                <h4 className="post__title" style={{ textAlign: 'center' }}>
                  {post.title}
                </h4>
                <p className="post__description">{post.description}</p>
                <p className="post__date">{formatDate(post.date)}</p>
                {post.author && (
                  <p className="post__author">By: {post.author}</p>
                )}
                {post.siteName && post.link && (
                  <div className="post__attribution">
                    <p>
                      This article was originally published on{' '}
                      <a href={post.link} target="_blank">
                        {post.siteName}
                      </a>
                    </p>
                  </div>
                )}
                <div className="post__content">
                  <AugmentedPortableText value={post.body} />
                </div>
              </div>
            </section>
          </Container>
        </Box>
      </>
    </Modal>
  )
}

export default NewsModal
