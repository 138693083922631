import { Box, Divider, Tab, Tabs } from '@mui/material'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import Logo from '~/components/global/logo'

const VALID_TABS = ['/', '/insights', '/resources', 'analytics', '/community']

const Layout = (props) => {
  const pathName = usePathname()

  return (
    <Box>
      <Box display="flex" flexDirection="row">
        <Box sx={{ marginRight: '1rem' }}>
          <Logo width={150} height={50} variant="SIDEBAR" />
        </Box>

        <Tabs
          sx={{
            '& .MuiTab-root': {
              color: 'rgba(255, 255, 255, 0.95)',
              fontSize: '20px',
              borderRadius: '3px',
              margin: '5px',
            },
          }}
          value={VALID_TABS.includes(pathName) ? pathName : false}
        >
          <Tab label="Home" component={Link} href="/" value="/" />
          <Tab
            label="Insights"
            component={Link}
            href="/insights"
            value="/insights"
          />
          <Tab
            label="Resources"
            component={Link}
            href="/resources"
            value="/resources"
          />
          <Tab
            label="Analytics"
            component={Link}
            href="/analytics"
            value="/analytics"
          />
          <Tab
            label="Community"
            component={Link}
            href="/community"
            value="/community"
          />
        </Tabs>
      </Box>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      {props.children}
    </Box>
  )
}

export default Layout
