import { PortableText } from '@portabletext/react'
import urlBuilder from '@sanity/image-url'
import { getImageDimensions } from '@sanity/asset-utils'
import ReactPlayer from 'react-player'
import youTubeEmbed from '~/schemas/youTubeEmbed'
import React from 'react'
import Image from 'next/image'

const Small = (props) => {
  return <p style={{ fontSize: 12 }}>{props.children}</p>
}

const YouTubeEmbed = ({ value }) => {
  const { url } = value
  if (!url) return null

  // Extract video ID from the YouTube URL
  const videoId = url.split('v=')[1]
  const embedUrl = `https://www.youtube.com/embed/${videoId}`

  return (
    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
      <iframe
        src={embedUrl}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  )
}

// Barebones lazy-loaded image component
const SampleImageComponent = ({ value, isInline }) => {
  const { width, height } = getImageDimensions(value)
  return (
    <Image
      src={urlBuilder({
        projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
        dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
      })
        .image(value)
        .fit('max')
        .auto('format')
        .url()}
      alt={value.alt || ' '}
      loading="lazy"
      width={width}
      height={height}
      style={{
        // Display alongside text if image appears inside a block text span
        display: isInline ? 'inline-block' : 'block',
        width: '80%',
        height: 'auto',
        margin: '0 auto',
        // Avoid jumping around with aspect-ratio CSS property
        aspectRatio: width / height,
      }}
    />
  )
}

const components = {
  types: {
    image: SampleImageComponent,
    // Any other custom types you have in your content
    // Examples: mapLocation, contactForm, code, featuredProjects, latestNews, etc.
    youTubeEmbed: YouTubeEmbed,
  },
  block: {
    small: Small, // todo: fix this
  },
  marks: {
    link: (props) => {
      const { children, value } = props
      return (
        <a href={value.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      )
    },
  },
}

export const AugmentedPortableText = (props) => {
  return <PortableText value={props.value} components={components} />
}
