export function formatDate(date: string) {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  })
}

export function isValidDate(date: unknown) {
  return date instanceof Date && !isNaN(date.getTime())
}
